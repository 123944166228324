.container1 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    margin-bottom: 3rem;
}

.contaner2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
}