.about {
    margin-top: 0;
    padding: 100px 0 100px 0;
    /** background-color: #040C18;**/
    /** background-image: url('./assets/img/banner-bg.png'); **/
    background:-moz-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 25%);
  
    /* safari 5.1+,chrome 10+ */
    background:-webkit-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 25%);
  
    /* opera 11.10+ */
    background:-o-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 25%);
  
    /* ie 10+ */
    background:-ms-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 25%);
  
    /* global 92%+ browsers support */
    background:radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 25%);
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
}

.about .tagline {
    font-weight: 700;
    letter-spacing: 0.8px;
    padding: 8px 10px;
    /** background: linear-gradient(90.21deg, rgba(170, 54, 124, 0.5) -5.91%, rgba(74, 47, 189, 0.5) 111.58%); **/
    
    border: 1px solid rgba(255, 255, 255, 0.5);
    font-size: 20px;
    margin-bottom: 16px;
    display: inline-block;
  }
  
.about #para{
    text-align: justify;
    text-justify: inter-word;
  }
  
.about h2 {
    font-size: 60px;
    font-weight: 700;
    letter-spacing: 0.8px;
    line-height: 1;
    margin-bottom: 20px;
    display: block;
  }
.about p {
    color: #B8B8B8;
    font-size: 18px;
    letter-spacing: 0.8px;
    line-height: 1.5em;
    width: 96%;
}
  
.about #p2 {
    margin-top: 3.5rem;
    color: #fff;
}


@keyframes updown {
      0% {
          transform: translateY(-20px);
      }
      50% {
          transform: translateY(20px);
      }
      100% {
          transform: translateY(-20px);
      }
  }
.txt-rotate > .wrap {
    border-right: 0.08em solid #666;
}

#aspire{
	font-size: 35px;
	font-weight: 700;
}

#aboutus{
  text-align: center;
	font-size: 45px;
	font-weight: 700;
  margin-bottom: 2rem;
}


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .about {
    margin-top: 0;
    padding: 50px 0 50px 0;
  }
  .about #careimg{
    margin-bottom: 1.5rem;
  }

  .about p {
    font-size: 14px;
    width: 100%;
  }

  .about #p2 {
    margin-top: 1.5rem;
  }
  #aspire{
    font-size: 25px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .about #careimg{
    margin-bottom: 2rem;
  }

  .about #p2 {
    margin-top: 1.5rem;
  }
  .about p {
    font-size: 15px;
    width: 100%;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .about #careimg{
    margin-bottom: 2.5rem;
  }

  .about #p2 {
    margin-top: 2rem;
  }

  .about p {
    font-size: 16px;
    width: 96%;
  }
}




