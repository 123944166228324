/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .project {
      padding: 50px 0;
      position: relative;
      background-color: black;
    }
    .project h2 {
      font-size: 30px;
      font-weight: 600;
      text-align: center;
    }
    .project p {
      color: #B8B8B8;
      font-size: 16px;
      line-height: 1.5em;
      margin: 14px auto 30px auto;
      text-align: center;
    }
}

@media only screen and (min-width: 1024px) {
  .banner {
    padding: 180px 0 100px 0;
  }
  .project {
    padding: 20px 0 10px 0;
    position: relative;
    background-color: black;
  }
  .proj-imgbx {
    position: relative;
    border-radius: 15px;
    overflow: hidden;
    margin-bottom: 24px;
  }
  .proj-txtx {
    font-size: 12px;
  }
}