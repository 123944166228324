.proj-txtx a{
    text-decoration: none;
    color: inherit;
}



/* Extra small devices (phones, 600px and down) */
@media only screen and (max-device-width: 600px) {
    .proj-txtx a{
        display: inline;
        font-size: 15px;
    }
    .proj-txtx span {
        line-height: normal;
        font-weight: 400;
        font-size: 13px;
        letter-spacing: 0.8px;
    }
}


/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-device-width: 768px) and (max-device-width: 961px){

    .proj-txtx #tag{
        font-size: 12px;
        margin-top: 10px;
    }
    .proj-txtx a{
        display: none;
    }
    
    .proj-txtx span {
        line-height: normal;
        font-weight: 300;
        font-size: 10px;
        letter-spacing: 0.5px;
    }
    
} 
/* Medium devices (landscape tablets, 768px and up) 
@media only screen and (min-width: 768px){
    .proj-txtx #tag{
        font-size: 12px;
        margin-top: 10px;
    }
    .proj-txtx a{
        display: none;
    }
    
    .proj-txtx span {
        line-height: normal;
        font-weight: 300;
        font-size: 10px;
        letter-spacing: 0.5px;
    }
    .proj-txtx a{
        display: inline;
        font-size: 17px;
    }
    .proj-txtx span {
        line-height: normal;
        font-weight: 400;
        font-size: 17px;
        letter-spacing: 0.8px;
    }
}
*/

/* @media only screen and (max-width: 1024px) {
    .proj-txtx #tag{
        font-size: 18px;
        margin-top: 10px;
    }
    .proj-txtx a{
        display: inline;
    }
    .proj-txtx span {
        font-style: italic;
        font-size: 15px;
        letter-spacing: 0.8px;
      }
}*/
  
  